/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./variables.scss";
@import "../assets/common/media.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "primeicons/primeicons.css";
body,
body .p-component {
  font-family: "Poppins", sans-serif;
}

label {
  color: $form-label;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}

.pac-container {
  z-index: 9999 !important;
}

.map-container {
  width: auto !important;
}

.description-color {
  color: $form-label;
}

.text-primary-red {
  color: $primary-red-color;
}

.text-primary-gray {
  color: $primary-gray-color;
}

.text-primary-blue {
  color: $portal-primary-blue-color;
}

.vertical-line {
  max-height: 80vh;
  width: 0px;
  border-left: 1px solid #ebeff2;

  &.sharp-border {
    border-left: 1px solid #90a0b7;
  }
  &.primary-red-border {
    border-color: $primary-red-color;
  }
}

.bg-primaryblue {
  background: $primary-blue-color;
}

.bg-primary-red {
  background: $primary-red-color;
}

.bg-light {
  background-color: #f9f9f9;
}

.primary-bg-color {
  background-color: $portal-primary-background-color;
}

.resend-button {
  font-weight: 500;
  color: #2c71c3;
  cursor: pointer;
}

.flag {
  width: 20px;
  height: 20px;
}
.overlay-nav li:hover {
  background-color: #cd9171;
  color: #ffffff;
}

.overlay-nav-notification li:hover {
  background-color: #cd9171;
  color: #ffffff;
  p {
    color: white;
  }
}

.p-tooltip {
  font-size: 0.875rem;
  &.sky {
    .p-tooltip-text {
      background-color: #88ccdc;
      color: map-get(map-get($palette, light), 1);
    }
  }
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}

/** Graph Css */
.circle-info-indicators {
  display: flex;
  justify-content: space-around;

  .ball-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4c5862;
    padding-top: 10px;
  }
  .bg-lightest-primary {
    background-color: #cd9171;
  }
  .bg-lightest-primary-dark {
    background-color: #ecdece;
    color: #334d6e !important;
  }
  .ball-outer {
    display: inline-flex;
    flex-direction: column;
    .ball {
      border-radius: 50%;
      background-color: #f2f0ea;
      display: flex;
      align-items: center;
      justify-content: center;
      .top-floating {
        position: absolute;
        top: -8px;
        right: 4px;
      }
      .success-box {
        color: #fff;
        border-radius: 4px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.02em;
        padding: 4px 8px;
        background: #2ed47a;
      }
    }
    .position-rel {
      position: relative;
    }
    .ball-inner {
      border-radius: 50%;
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      margin: 13px;
    }
    .buttom-floating {
      position: absolute;
      bottom: -6px;
      left: 10px;
    }
    .null-box {
      background-color: #99a6b7;
      color: #fff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.02em;
      padding: 4px 8px;
    }
  }
}

/** Global modal progress button css */
.processbutton {
  flex-shrink: 0;
  background-color: #ffffff;
  padding: 15px;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #ebeff2;
}
//.p-dialog-header-close-icon {
// color: white;
//}
.p-dialog-footer {
  border-top: 1px solid #ebeff2;
}

/** Custom Dropdown Global */
.custom-dropdown {
  .p-dropdown-label {
    line-height: 9px;
    font-size: 11px;
    height: 32px;
  }
}
.p-dropdown-filter.p-inputtext.p-component {
  height: 32px !important;
}

/** Country Select Menu */
.custom-menu-select {
  .p-menu {
    ul {
      margin: 0px !important;
    }
  }
  .p-submenu-header {
    font-weight: 500 !important;
  }
  .p-menuitem-text {
    font-size: 12px;
    font-weight: 400;
  }
  .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #828282;
  }
}

.custom-marker {
  position: absolute;
  z-index: 100000000;
  .hand-banner {
    min-width: 160px;
    width: 160px;
    position: relative;
  }

  .rectangle {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    img {
      height: 30px;
      width: 30px;
    }
  }

  .pipe {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 6px;
    height: 30px;
    background-color: #333333;
    border-radius: 0px 0px 10px 10px;
  }
}

/** Truncate More Text */
.truncate-more {
  font-size: 16px;
  font-weight: 600;
  color: #cd9171;
}

.custom-tooltip-class {
  max-width: 400px !important; /* Set your desired width */
}

// For the carousel
.analytics {
  position: relative;
  .p-carousel-prev,
  .p-carousel-next {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    position: absolute;
    bottom: -3px;
    z-index: 1;
  }
  .p-carousel-next {
    right: 0;
  }
}
