@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;400;500;600;800;900&display=swap");

// border
$border-thin: 1px solid !default;
$border-thick: 2px solid !default;
$border-thicker: 3px solid !default;

// font family
$poppins: "Poppins", sans-serif !important;
$openSans: "Open Sans", sans-serif !important;
$inter: "Inter", sans-serif !important;

// color palette
$palette: (
  primary: (
    1: #192a3e,
    2: #2c71c3,
    3: #334d6e,
    4: #1f8dc6,
  ),
  secondary: (
    1: #828282,
    2: #ebeff2,
    3: #666666,
    4: #6a707e,
    5: #90a0b7,
    6: #707070,
    7: #707683,
    8: #99a6b7,
  ),
  light: (
    1: #ffffff,
  ),
  dark: (
    1: #000,
  ),
  danger: (
    1: #d42f2f,
    2: #ecdece,
    3: #cd9171,
  ),
  warn: (
    1: #fbc400,
  ),
  success: (
    1: #198754,
  ),
);

$primary-red-color: #d42f2f;
$primary-gray-color: #666666;
$primary-blue-color: #238ec5;

// Portal color
$portal-primary-background-color: #ecdece;
$portal-primary-blue-color: #2c71c3;

/** Form field */
$form-label: #192a3e;
