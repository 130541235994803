@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;400;500;600;800;900&display=swap");
/** Form field */
.datatable.p-datatable .p-datatable-thead > tr > th {
  background-color: #ffffff;
  color: #334d6e;
  font-weight: 500;
  font-size: 13px;
}
.datatable.p-datatable .p-datatable-tbody tr td {
  color: #828282;
}
.datatable.p-datatable .no-data {
  min-height: 400px;
}
.datatable.p-datatable .p-paginator {
  justify-content: end;
}
.datatable.p-datatable .p-paginator .p-paginator-current {
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-element {
  color: #828282;
  min-width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-element .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-rpp-options {
  height: 2rem;
  align-items: center;
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-rpp-options .p-inputtext {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}
.datatable.p-datatable .p-paginator .p-paginator-rpp-options .p-dropdown-trigger-icon {
  width: 0.875rem;
  height: 0.875rem;
}

.primeNg-inputs {
  height: 32px;
  border: 1px solid #ebeff2;
  border-radius: 4px !important;
  font-size: 11px !important;
}

.primeNg-inputs-list-header {
  height: 38px;
  border: 1px solid #ebeff2;
  border-radius: 4px !important;
  font-size: 13px !important;
}

.p-dialog.custom-filter-mobile {
  transition: none;
  transform: none;
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  max-height: 100%;
  height: 100%;
}
.p-dialog.custom-filter-mobile .p-dialog-header {
  border-bottom: 1px solid #ebeff2;
  background-color: white;
}
.p-dialog.custom-filter-mobile .p-dialog-footer {
  padding-top: 1.5rem;
  border-top: 1px solid #ebeff2;
}
.p-dialog.custom-filter-mobile .p-dialog-header-close-icon {
  width: 25px;
  height: 25px;
}
.p-dialog.custom-filter-mobile .p-dialog-header-maximize {
  display: none;
  pointer-events: none;
}
.p-dialog.custom-filter-mobile .tab-calendar .p-inputwrapper .p-inputtext {
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #2c71c3;
  width: 36px;
  padding-right: 0;
}
.p-dialog.custom-filter-mobile .tab-calendar .p-inputwrapper .mobile-screen-calendor {
  width: 0 !important;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #dee2e6; /* Background color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #c5ced7; /* Background color of the scrollbar thumb on hover */
}